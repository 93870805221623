tr.folder a,
tr.file a,
.item-actions li a {
  display: flex;
  align-items: center;
  color: #000;
}
tr.folder svg {
  margin-right: 5px;
}
.rendered-react-keyed-file-browser {
  width: 100%;
}
.sidebar-list div a:first-child {
  display: none;
}
.sidebar-list div a:nth-child(5) svg,
.sidebar-list div a:nth-child(7) svg {
  color: transparent;
}
.sidebar-list div a:nth-child(5)::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("./vendor/ic-nft.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.sidebar-list div a:nth-child(7)::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("./vendor/history.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.sidebar-list div a:nth-child(6) {
  display: none;
}
.template {
  border: solid 4px transparent;
  border-radius: 15px;
}
.template.active {
  border: solid 4px #3cb371;
}
.form_input.required,
.percentage_input.required,
.address_input.required,
.transfer_input.required {
  border: solid 1px red;
}
.dashboard_grid .home_page {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;
  grid-auto-rows: minmax(min-content, max-content);
}
.archive_file .menu_file_button,
.archive_file .menu_folder_button {
  display: none;
}
.drop_item::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  background-image: url("./vendor/locker.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: -70px;
}
.drop_item::after {
  content: "Drop Your Files here";
  position: absolute;
  font-family: "DM Sans,sans-serif";
}
.mint_nft_file .mint_button,
.mint_nft_file .mint_to_button {
  cursor: pointer;
  background-color: #0078ff;
  height: 35px;
  color: #fff;
  width: 100px;
  border-radius: 5px;
  margin: 20px auto;
}
.mint_nft_file .mint_to_button {
  width: 150px;
}
.file_card {
  height: calc(100% - 50px);
  position: relative;
  overflow: hidden;
  /* background-image: url("./vendor/grabbing_file.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
}
img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}
.MuiRadio-colorSecondary.Mui-checked,
.MuiSwitch-colorPrimary.Mui-checked {
  color: #ffffff;
}
.auction_options .MuiRadio-colorSecondary.Mui-checked,
.auction_options .MuiSwitch-colorPrimary.Mui-checked {
  color: #000000;
}
.auction_options .MuiRadio-colorSecondary.Mui-checked + span {
  font-weight: bold;
}
.MuiSwitch-track {
  border-radius: 20px;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #00c783;
  opacity: 1;
}
.MuiSwitch-switchBase {
  left: 4px;
}
.MuiSwitch-root {
  height: 45px;
  width: 64px;
}
.MuiSwitch-thumb {
  width: 17px;
  height: 17px;
  margin-top: 5px;
}
.MuiIconButton-root:hover,
.MuiSwitch-colorPrimary.Mui-checked:hover {
  background-color: transparent;
}
.react-swipeable-view-container {
  padding: 20px 0;
}
div:has(> .react-swipeable-view-container) {
  width: 100%;
}
.error_label,
.error_label2 {
  text-align: left;
  margin: 0;
  margin-top: -5px;
  color: red;
}
